import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"gamefolders",
        "id":"gamefolder_id",
        "options":["edit","insert","delete"],
        "subidname":"gamefolder_name",
        "nameSingle":"game folder",
        "nameMultiple":"game folders",
        "extraoptions":[
            {
                "name":"Games",
                "page":"/games",
            }
        ],
        "fields":{
            "field1":{
                "name":"Name",
                "field":"gamefolder_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            }
        }
    }
    
}